/** @format */

@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap");
a {
  text-decoration: none !important ;
}
* {
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  box-sizing: border-box;
}

::-webkit-scrollbar-track {
  background: #ffffff !important;
}
::-webkit-scrollbar-thumb {
  background: #1568ff !important;
  border-radius: 16px;
}
::-webkit-scrollbar {
  width: 5px;
}

//navbar
.navbar-toggler {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}
.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none !important;
  position: relative;
  border-bottom: 2px solid #000;
  transition: all 300ms linear;
  outline: none !important;
}
.navbar .navbar-toggler-icon:after,
.navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: #000;
  top: 0;
  left: 0;
  content: "";
  z-index: 2;
  transition: all 300ms linear;
}
.navbar .navbar-toggler-icon:after {
  top: 8px;
}

.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg) !important;
}
.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg) !important;
}
.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent !important;
}

.navbar {
  position: fixed !important;
  width: 100% !important;
  top: 0 !important;
  left: 0 !important;
  z-index: 9 !important;
  .nav-link {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 170%;
    letter-spacing: 0.005em;
    color: #272a2d;
    &:hover {
      color: #1568ff;
    }
  }
}
.nav-btns {
  padding: 18px 32px;
  border-radius: 120px;
  background-color: #1568ff;
  -webkit-transition: all 350ms ease;
  transition: all 350ms ease;
  font-family: Poppins, sans-serif;
  font-size: 0.73em;
  line-height: 1.04em;
  letter-spacing: 0.25em;
  text-transform: uppercase;
  color: white;
}
.navbar-fixed-top {
  animation-duration: 0.8s;
  animation-name: fadeInDown;
  animation-timing-function: ease-in-out;
  -webkit-box-shadow: 0px 7px 15px -12px rgba(35, 35, 35, 0.15);
  -moz-box-shadow: 0px 7px 15px -12px rgba(35, 35, 35, 0.15);
  box-shadow: 0px 7px 15px -12px rgba(35, 35, 35, 0.15);
  left: 0;
  position: fixed !important;
  right: 0;
  top: 0;
  background: #fff !important;
  width: 100%;
  z-index: 999;
}
/*----------------------------------------*/
/*  Hero Style 1
/*----------------------------------------*/
.hero-area {
  position: relative;
}
.in-progress {
  color: #fd6d6f !important;
}
.heroV1 {
  position: relative;
  padding-top: 0px;
  padding-bottom: 0px;
  z-index: 1;
}
.heroV1:before {
  position: absolute;
  content: "";
  height: 380px;
  width: 275px;
  top: 0;
  left: 0;
  background-size: 100% 100%;
  background-image: url(../public/assets/bg/hero-shape-left.png);
  background-repeat: no-repeat;
  z-index: -1;
}
.heroV1:after {
  position: absolute;
  content: "";
  height: 445px;
  width: 620px;
  top: 0;
  right: 0;
  background-size: 100% 100%;
  background-image: url(../public/assets/bg/hero-shape-right.png);
  background-repeat: no-repeat;
  z-index: -2;
}
.heroV1 .hero-img {
  -webkit-animation: pulse 5s infinite linear;
  -moz-animation: pulse 5s infinite linear;
  -ms-animation: pulse 5s infinite linear;
  animation: pulse 5s infinite linear;
}
.heroV1 .hero-content {
  position: relative;
  padding: 20px 0px;
}
.heroV1 .hero-content .hero-text {
  position: relative;
}
.heroV1 .hero-content .hero-text h2 {
  margin-top: 20px;
  margin-bottom: 0.4em;
  font-size: 2.4em;
  font-family: Poppins, sans-serif;
  color: #3d006f;
  line-height: 130%;
  font-weight: 600;
}

.heroV1 .hero-content .hero-text p {
  color: #716c80;
  font-size: 1.04em;
  line-height: 1.82em;
  font-weight: 300;
}
.heroV1 .hero-btn {
  position: relative;
  margin-top: 10px;
}
.heroV1 .hero-btn a.btn-style-c {
  margin-left: 20px;
  cursor: pointer;
}

.hv1-box {
  position: relative;
  padding-bottom: 50px;
  overflow: hidden;
}
.btn-style {
  margin-right: 16px !important;
  padding: 18px 32px !important;
  border-radius: 120px !important;
  background: #458dfc !important;
  display: inline-block !important;
  -webkit-transition: all 350ms ease !important;
  transition: all 350ms ease !important;
  font-family: Poppins, sans-serif !important;
  font-size: 0.73em !important;
  line-height: 1.04em !important;
  letter-spacing: 0.25em !important;
  text-transform: uppercase !important;
  color: white !important;
}
.hero-text .a {
  background: #ca5a21 !important;
}
.hero-icons {
  display: flex;
  a {
    text-decoration: none;
    font-style: normal;
    font-size: 1.38em !important;
    border-radius: 100%;
    color: #fff;
    background: #3b67a8;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    margin: 10px;
  }
}
.aan{
  background: #fd6d6f !important;
  animation: myAnim 2s linear 0s infinite alternate-reverse both;
}
@keyframes myAnim {
	0% {
		transform: scale(0.9);
	}

	100% {
		transform: scale(1.2);
	}
}
/*----------------------------------------*/
/*  Section 1
/*----------------------------------------*/
.sec-title {
  font-family: Poppins, sans-serif;
  color: #3d006f;
  font-size: 4.69em;
  line-height: 90%;
  font-weight: 400;
  text-align: center;
}
.sec-h6 {
  margin-top: 20px;
  margin-bottom: 35px;
  font-family: Poppins, sans-serif;
  color: #3d006f;
  font-size: 1.3em;
  line-height: 100%;
  font-weight: 600;
  position: relative;

  font-style: italic;
}
.section1 {
  .sec-box {
    height: 90vh !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  h3 {
    margin-top: 20px;
    margin-bottom: 35px;
    font-family: Poppins, sans-serif;
    color: #3d006f;
    font-size: 3.13em;
    line-height: 100%;
    position: relative;
    font-weight: 400;
  }
  h6 {
    margin-top: 20px;
    margin-bottom: 35px;
    font-family: Poppins, sans-serif;
    color: #3d006f;
    font-size: 1.3em;
    line-height: 100%;
    font-weight: 500;
  }
  p {
    font-family: Nunito, sans-serif;
    color: #716c80 !important;
    font-size: 1.04em;
    line-height: 1.82em;
    font-weight: 300;
    overflow: visible;
    // width: 21.56em;
    opacity: 1;
  }
}
.nav-tabs {
  border-bottom: 0 !important;
}
.nav-tabs .nav-link.active,
.hero-tabs .nav-tabs .show > .nav-link {
  background: transparent !important;
  color: #000 !important;
  border-bottom: 3px solid #3d006f !important;
  outline: none !important;
}
.nav-tabs .nav-link {
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: Poppins, sans-serif;
  color: #3d006f;
  font-size: 0.78em;
  line-height: 21px;
  font-weight: 700;
  text-transform: uppercase;
  border: 1px solid transparent !important;
}
.nav-tabs .nav-item {
  margin: 10px;
}

.how-to-get {
  position: relative;
  height: 58em;
  background-image: url(../public/assets/bg/bg2x.png);
  background-position: 50% 100%;
  background-size: cover;
  background-repeat: no-repeat;

  h3 {
    margin-top: 10px;
    margin-bottom: 10px;
    font-family: Poppins, sans-serif;
    color: #3d006f;
    font-size: 1.82em;
    line-height: 110%;
    font-weight: 400;
  }
  p {
    font-family: Nunito, sans-serif;
    color: #716c80;
    font-size: 1.04em;
    line-height: 1.82em;
    font-weight: 300;
  }
}
.section-heading {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  h2 {
    position: absolute;
    top: 220px;
  }
  .accent-large {
    height: auto;
    font-family: Poppins, sans-serif;
    color: #ecf1ff;
    font-size: 13.02em;
    line-height: 375px;
    font-weight: 600;
    text-align: center;
  }
  .accent-large.left-justify {
    position: relative;
    top: -114px;
    text-align: left;
  }
}
.section-heading.is--buy {
  position: absolute;
  left: 0%;
  right: 0%;
  bottom: auto;
  z-index: 0;
  height: 14em;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.stake {
  position: relative;
  height: 62em;
  background-image: url(../public/assets/bg/bg2x.png);
  background-position: 50% 100%;
  background-size: cover;
  background-repeat: no-repeat;
  h3 {
    margin-top: 20px;
    margin-bottom: 35px;
    font-family: Poppins, sans-serif;
    color: #3d006f;
    font-size: 3.13em;
    line-height: 100%;
    position: relative;
    font-weight: 400;
  }
  h6 {
    margin-top: 20px;
    margin-bottom: 35px;
    font-family: Poppins, sans-serif;
    color: #3d006f;
    font-size: 1.3em;
    line-height: 140%;
    font-weight: 600;
  }
  p {
    font-family: Nunito, sans-serif;
    color: #716c80 !important;
    font-size: 1.04em;
    line-height: 1.82em;
    font-weight: 300;
    overflow: visible;
    // width: 21.56em;
    opacity: 1;
  }
}
.stake-img {
  background-image: url(../public/assets/stake.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.partner {
  position: relative;
  height: 48em;
  background-image: url(../public/assets/bg/bg2x.png);
  background-position: 50% 100%;
  background-size: cover;
  background-repeat: no-repeat;
  h5 {
    margin-top: 10px;
    margin-bottom: 10px;
    font-family: Poppins, sans-serif;
    color: #3d006f;
    font-size: 1.56em;
    line-height: 140%;
    font-weight: 400;
    letter-spacing: 0.1em;
    z-index: 999 !important;
    position: relative;
    text-transform: uppercase;
  }
  h2 {
    font-family: Poppins, sans-serif;
    color: #3d006f;
    font-size: 4.69em;
    line-height: 90%;
    font-weight: 400;
  }
  p {
    width: 27.08em;
    margin-top: 1.82em;
    margin-bottom: 1.82em;
    font-family: Nunito, sans-serif;
    color: #716c80;
    font-size: 1.04em;
    line-height: 1.82em;
    font-weight: 300;
  }
}
.img-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 200px;
  height: 100px;
  padding: 24px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  border-radius: 30px;
  background-color: #fff;
  // background-image: url(../images/mexc.jpg);
  background-position: 50% 50%;
  background-size: 125px;
  background-repeat: no-repeat;
  box-shadow: 1px 1px 59px 0 rgba(61, 0, 111, 0.1);
}
.tokeomics {
  h3 {
    margin-top: 20px;
    font-family: "Poppins", sans-serif;
    color: #fd6d6f;
    font-size: 1.53em;
    line-height: 100%;
    font-weight: 600;
  }
  h5 {
    margin-bottom: 10px;
    font-family: Poppins, sans-serif;
    color: #716c80;
    font-size: 1.16em;
    line-height: 140%;
    font-weight: 400;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }
}
.roadmap {
  position: relative;
  height: 68em;
  background-image: url(../public/assets/bg/bg2x.png);
  background-position: 50% 100%;
  background-size: cover;
  background-repeat: no-repeat;
  .roadmap-warper {
    transform-style: preserve-3d;
    position: relative;
    width: 100%;
    height: 100%;
    overflow-x: auto !important;
    overflow-y: hidden !important;
    -webkit-user-select: none;
    user-select: none;
    -webkit-overflow-scrolling: auto !important;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    // width: 300em;
    // height: 30em;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .phase-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: auto;
    height: 100%;
    margin-right: 0em;
    margin-bottom: 3.33em;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .phase {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
  .accent-small {
    font-family: Poppins, sans-serif;
    color: #dfdfff;
    font-size: 4.69em;
    line-height: 135px;
    font-weight: 400;
    text-transform: uppercase;
  }
  .phase-details {
    position: relative;
    left: -2.4em;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  h6 {
    margin-top: 10px;
    margin-bottom: 10px;
    font-family: Poppins, sans-serif;
    color: #858c94;
    font-size: 0.78em;
    line-height: 21px;
    font-weight: 700;
    text-transform: uppercase;
  }
  h3 {
    margin-top: 20px;
    margin-bottom: 35px;
    font-family: Poppins, sans-serif;
    color: #3d006f;
    font-size: 3.13em;
    line-height: 100%;
    font-weight: 400;
  }
  .phase-activities {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: auto;
    height: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .phase-activity-item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: auto;
    height: auto;
    margin-bottom: 0.42em;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .green-checkmark {
    height: 1.82em;
    margin-right: 2.08em;
    -o-object-fit: scale-down;
    object-fit: scale-down;
  }
  .no-bottom-padding {
    margin-bottom: 0px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    white-space: nowrap;
  }
  p {
    font-family: Nunito, sans-serif;
    color: #716c80;
    font-size: 1.04em;
    line-height: 1.82em;
    font-weight: 300;
  }
}
.roadmap .roadmap-warper::-webkit-scrollbar-thumb {
  background: transparent !important;
  border-radius: 15px !important;
  border-bottom: 2px dashed #716c80;
}
.roadmap .roadmap-warper::-webkit-scrollbar-track {
  background: transparent !important;
}

.roadmap .roadmap-warper::-webkit-scrollbar {
  width: 2px !important;
  height: 5px !important;
}
.faqs {
  position: relative;
  height: auto;
  margin-top: 6em;
  padding-bottom: 180px;
  background-image: url(../public/assets/bg/bg2x.png);
  background-position: 50% 100%;
  background-size: cover;
  background-repeat: no-repeat;
}

.styles_faq-row-wrapper__3vA1D {
  background: transparent !important;
}
.faq-row {
  background: #fff;
  border-bottom: none !important;
  border-radius: 5px;
  box-sizing: content-box;
  box-shadow: 0px 4px 8px rgba(108, 108, 108, 0.05);
  border-radius: 20px;
  margin: 20px auto !important;
  padding-left: 20px !important;
  width: 75% !important;
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px !important;
  line-height: 130%;
  color: #2c3a4b !important;
}
.row-content-text {
  border-radius: 0px;
  font-family: Poppins, sans-serif;
  font-style: normal;
  text-align: left;
  white-space: normal;
  font-size: 14px !important;
  font-weight: 400 !important;

  -o-object-fit: fill;
  object-fit: fill;
}
.join-us {
  position: relative;
  bottom: -9.6em;
  z-index: 1;
  width: 100%;
  height: 42em;
  margin-top: -8em !important;
  background-image: url(../public/assets/bg/bg2x.png);
  background-position: 50% 100%;
  background-size: cover;
  background-repeat: no-repeat !important;
  .accent-large {
    height: auto;
    font-family: Poppins, sans-serif;
    color: #ecf1ff;
    font-size: 13.02em;
    line-height: 5px !important;
    font-weight: 600;
    text-align: center;
  }
  p {
    font-family: Nunito, sans-serif;
    color: #716c80;
    font-size: 1.04em;
    line-height: 1.82em;
    font-weight: 300;
  }
}
.red-button {
  background-color: #fd6d6f;
}
.red-button:hover {
  background-color: #3a7de4 !important;
  box-shadow: 6px 6px 13px 0 rgba(0, 0, 0, 0.18) !important;
  -webkit-transform: translate(0px, -8px) !important;
  -ms-transform: translate(0px, -8px) !important;
  transform: translate(0px, -8px) !important;
  color: #fff;
}
.btn-style:hover {
  background-color: #fd6d6f !important;
  box-shadow: 6px 6px 13px 0 rgba(0, 0, 0, 0.18) !important;
  -webkit-transform: translate(0px, -8px) !important;
  -ms-transform: translate(0px, -8px) !important;
  transform: translate(0px, -8px) !important;
  color: #fff !important;
}

.footer {
  // background: #09142e !important;
  height: 34em !important;
  background-image: url(../public/assets/bg/white-copy2x.png),
    url(../public/assets/bg/bg2x_1.png);
  background-position: 0px 0px, 0px 0px !important;
  background-size: contain, auto !important;
  background-repeat: repeat-y, repeat !important;
  svg {
    margin-top: 20px;
    color: #a5abb3;
    width: 40px;
    height: 20px;
  }
  hr {
    border: 1px solid #a5abb3;
  }
  p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 170%;
    letter-spacing: 0.005em;
    color: #858c94;
  }
}
:root {
  --theme-color: #0a254e;
  --white-color: #fff;
  --black-color: #000;
  --btn-color: #2dccd3;
  --primary-color: #0b2a57;
  --secondary-color: #16396c;
}
.nfts {
  h1 {
    font-size: 80px;
    line-height: 60px;
    color: #3d006f;
  }
  /*global styles ends*/
  /*main section starts*/
  .main-section {
    background: var(--theme-color);
    border-radius: 30px;
    width: 1457px;
    margin: 70px auto;
    position: relative;
    overflow: hidden;
  }
  .donut-1 {
    position: absolute;
    top: 0;
    right: 0;
    filter: blur(5px);
    -webkit-filter: blur(5px);
    width: 18%;
  }
  .donut-2 {
    position: absolute;
    bottom: 0;
    right: 97px;
    filter: blur(5px);
    -webkit-filter: blur(5px);
    width: 14%;
  }
  /*main-section ends*/
  /*header styling ends*/
  /*banner styling starts*/
  .banner {
    background: url(../public/assets/bg/banner.png) no-repeat center center /
      cover;
    padding: 51px 0px 125px;
    position: relative;
    z-index: 5;
  }
  .spark-left {
    position: absolute;
    top: 50%;
    left: 5%;
    transform: translate(0%, -50%);
    -webkit-transform: translate(0%, -50%);
  }
  .spark-right {
    position: absolute;
    top: 60%;
    left: auto;
    right: 5%;
    transform: translate(0%, -50%);
    -webkit-transform: translate(0, -50%);
  }
  .spark-left img {
    animation: staroneanimation 2s infinite;
    animation-direction: alternate;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    display: block;
    margin: 0px auto 0px 0px;
  }
  @keyframes staroneanimation {
    0% {
      opacity: 0.2;
    }
    100% {
      opacity: 1;
    }
  }
  .spark-right img {
    animation: startwoanimation 2s infinite;
    animation-delay: 2s;
    animation-direction: alternate;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    display: block;
    margin: 0px 0px 0px auto;
  }
  @keyframes startwoanimation {
    0% {
      opacity: 0.2;
    }
    100% {
      opacity: 1;
    }
  }
  .banner-content {
    position: relative;
    z-index: 10;
  }
  .banner-content h1 {
    text-transform: uppercase;
    letter-spacing: 8px;
    font-weight: 700;
    z-index: 5;
    position: relative;
  }
  .banner-content p {
    margin-top: 1.82em;
    margin-bottom: 1.82em;
    font-family: Nunito, sans-serif;
    color: #716c80;
    font-size: 1.04em;
    line-height: 1.82em;
    font-weight: 300;
  }
  h3 {
    margin-top: 20px;
    margin-bottom: 35px;
    font-family: Poppins, sans-serif;
    color: #3d006f;
    font-size: 3.13em;
    line-height: 100%;
    position: relative;
    font-weight: 400;
  }
  h6 {
    margin-top: 20px;
    margin-bottom: 35px;
    font-family: Poppins, sans-serif;
    color: #3d006f;
    font-size: 1.3em;
    line-height: 140%;
    font-weight: 600;
  }
  .banner-content .banner-pig-images {
    margin-bottom: -28px;
    position: relative;
  }
  .banner-content .banner-pig-images img {
    width: 90%;
    display: inline-block;
  }
  .banner-content .theme-btn {
    display: inline-block;
  }
  .banner-pig-2 {
    z-index: 2;
  }
  .banner-pig-1 {
    transform: translate(23%, -6%);
    -webkit-transform: translate(23, -6%);
  }
  .banner-pig-3 {
    transform: translate(-23%, -6%);
    -webkit-transform: translate(-23, -6%);
  }
  /*banner styling ends*/

  @media (min-width: 1200px) and (max-width: 1399px) {
    .main-section {
      width: 95%;
      margin: 40px auto;
    }

    .banner-content .banner-pig-images img {
      width: 80%;
    }
    .banner-pig-1 {
      transform: translate(32%, -6%);
      -webkit-transform: translate(32%, -6%);
    }
    .banner-pig-3 {
      transform: translate(-34%, -6%);
      -webkit-transform: translate(-34%, -6%);
    }
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    .main-section {
      width: 95%;
      margin: 40px auto;
    }

    h1 {
      font-size: 70px;
      line-height: 55px;
    }
    .theme-btn {
      font-size: 20px;
      line-height: 28px;
      padding: 20px 38px;
    }

    /*banner styling starts*/
    .banner {
      padding: 50px 0px 90px;
    }
    .banner-content p {
      margin: 25px 0px;
      font-size: 18px;
      line-height: 26px;
    }
    .spark-left img,
    .spark-right img {
      width: 60%;
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
    .main-section {
      width: 95%;
      margin: 35px auto;
    }

    h1 {
      font-size: 50px;
      line-height: 40px;
    }
    .theme-btn {
      font-size: 16px;
      line-height: 24px;
      padding: 14px 34px;
    }

    /*banner styling starts*/
    .banner {
      padding: 40px 0px 90px;
    }
    .banner-content p {
      margin: 20px 0px 25px;
      font-size: 16px;
      line-height: 24px;
    }
    .spark-left img,
    .spark-right img {
      width: 50%;
    }
  }

  @media (max-width: 767px) {
    .main-section {
      width: 95%;
      margin: 35px auto;
      border-radius: 15px;
    }
    .container {
      width: 720px;
      max-width: 720px;
    }
    h1 {
      font-size: 35px;
      line-height: 35px;
    }
    .theme-btn {
      font-size: 16px;
      line-height: 24px;
      padding: 14px 24px;
    }

    /*banner styling starts*/
    .spark-left {
      left: -5%;
    }
    .spark-right {
      right: -5%;
    }
    .banner {
      padding: 20px 0px 40px;
      background-position: center -20px;
    }
    .banner-content .banner-pig-images {
      margin-bottom: -18px;
    }
    .banner-content p {
      margin: 20px 0px 25px;
      font-size: 15px;
      line-height: 24px;
    }
    .spark-left img,
    .spark-right img {
      width: 50%;
    }
  }
}
.timer h1 {
  display: inline-block;
  padding-left: 15px;
  text-align: center;

}

.timer {
  color: #2c3a4b;
  float: none;
  margin: 0 auto;
}

.timer small {
  font-weight: 300 !important;
  font-size: 11px !important;
}
.timer strong {
  font-size: 44px !important;
}
.token-bg {
  // background: url(../public/assets/chart-02.png) no-repeat bottom;
  background-size: contain;
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
}
@media only screen and (max-width: 600px) {
  .timer {
    text-align: center !important;
    width: 100% !important;
  }
  .accent-large {
    font-size: 4.02em !important;
    line-height: 75px !important;
  }
  .sec-title {
    font-size: 2.19em;
    // line-height: 10% !important;
  }
  .section-heading h2 {
    position: absolute;
    top: 81px;
  }
  .partner h2 {
    font-size: 2em;
    line-height: 90% !important;
  }
  .partner p {
    width: 100%;
  }
  .heroV1 .hero-content .hero-text h2 {
    margin-top: 100px;
    margin-bottom: 0.4em;
    font-size: 2.4em;
  }
  .sec-box {
    height: auto !important;
  }
  .section1 h3 {
    font-size: 2.13em;
    text-align: center;
  }
  .nav-tabs {
    margin-top: 100px !important;
  }
  .how-to-get {
    height: auto !important;
    padding-bottom: 150px !important;
  }
  .partner {
    height: auto !important;
    padding-bottom: 150px !important;
  }
  .partner {
    height: auto !important;
    padding-bottom: 150px !important;
  }
  .section1 {
    height: auto !important;
    padding-bottom: 150px !important;
  }
  .join-us {
    height: auto !important;
    padding-bottom: 150px !important;
  }
  .img-box {
    display: flex;
    width: 100%;
    height: 100px;
  }
  .tokeomics h3 {
    font-size: 1.13em;
  }
  .tokeomics h5 {
    font-size: 0.93em;
  }
  .roadmap h6 {
    margin-top: 40px;
  }
  .footer {
    height: auto !important;
    padding-bottom: 20px !important;
  }
  .row-re {
    flex-direction: column-reverse !important;
    margin-top: 50px !important;
  }
  .hero-img {
    display: none;
  }
}
// ======================== Token page layout ============================

.token-hero {
  background: #fff url(../public/assets/bg/banner.png) no-repeat center top /
    cover;
  padding-top: 80px;
  h2 {
    font-family: "Roboto";
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 2.125rem;
    color: #3b67a8;
  }
  .task-box {
    background-color: #fffefe;
    border-radius: 1.5rem;
    padding: 2rem;
    box-shadow: 1px 1px 59px 0 rgba(61, 0, 111, 0.1);
  }
  input {
    font-weight: 500;
    font-size: 0.85rem;
    line-height: 1.75rem;
    letter-spacing: 0.5px;
    width: 100%;
    -webkit-appearance: none;
    outline: none;
    border: none;
    background: #fffefe;
    border: 1px solid #706a6a96;
    padding: 0.625rem 1rem !important;
    height: 3rem;
    border-radius: 0.5rem;
  }
  p {
    font-weight: 500;
    color: #1a1b22;
    font-size: 0.9rem;
    line-height: 140%;
    display: block;
  }
  .circle {
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    background: #1568ff;
    color: #fff;
    font-size: 2rem;
    transition: background 0.3s ease-in-out;
  }
}
.banner-section {
  min-height: 25.875rem;
  position: relative;

  width: 100%;
  background: #1568ff;
  padding: 4.625rem 4rem;
  border-radius: 1.5rem;
  background: #1e1e1e url(../public/assets/bg/token-powerting.webp) no-repeat
    center/cover;
  h1 {
    font-weight: 800 !important;
    font-size: 3.75rem;
    // font-family: Poppins, sans-serif;
    line-height: 4.5rem;
    color: #ffffff;
    text-transform: uppercase;
    font-style: italic;
  }

  p {
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.75rem;
    color: #ffffff;
    letter-spacing: 0.5px;
    font-family: "Roboto";
  }
}

.airdrop-section {
  position: relative;
  min-height: 414px;
  width: 100%;

  padding: 4.625rem 2rem;
  background: radial-gradient(
      107.99% 155.74% at 43.33% 105.9%,
      #1568ff 0%,
      rgba(250, 201, 174, 0) 53.2%
    ),
    linear-gradient(339deg, #181818 0%, #5b5b5b 100%);
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
  border-radius: 1.5rem;
  h1 {
    font-weight: 800 !important;
    font-size: 3.75rem;
    // font-family: Poppins, sans-serif;
    line-height: 4.5rem;
    color: #ffffff;
    text-transform: uppercase;
    font-style: italic;
  }

  p {
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.75rem;
    color: #ffffff;
    font-family: "Roboto";
    letter-spacing: 0.5px;
  }
}
.airdrop-card {
  color: #edeff1;
  border-radius: 1.25rem 1.25rem 0.3125rem 0.3125rem;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.2) 67.91%,
    rgba(0, 0, 0, 0) 99.77%
  );
  -webkit-backdrop-filter: blur(50px);
  backdrop-filter: blur(50px);
  padding: 2.19rem;
  min-width: 50%;
  h2 {
    font-size: 2rem !important;
    font-weight: 700 !important;
    line-height: normal;
    letter-spacing: 0.03125rem;
    font-family: "Roboto";

    color: #ecf1ff;
    margin-bottom: 1.25rem;
  }
  h5 {
    margin-bottom: 1.25rem;
    font-size: 1.25rem;
    font-weight: 600 !important;
    line-height: normal;
    letter-spacing: 0.03125rem;
    font-family: "Roboto";
  }
  li {
    margin-bottom: 1.25rem;
    font-weight: 300;
    line-height: 120%;
    letter-spacing: 0.03125rem;
    list-style: square;
    strong {
      font-weight: 600 !important;
    }
  }
}

@media only screen and (max-width: 600px) {
  .task-box {
    text-align: center !important;
    button {
      margin-top: 20px;
    }
  }
  .circle {
    margin: auto;
    margin-bottom: 20px;
  }
  .banner-section {


    h1 {
      font-size: 1.99rem;
      line-height: 1.9rem;
    }

    p {
      width: 100% !important;
    }
    button {
      width: 100% !important;
    }
    img{
      width: 100% !important;
    }
  }
  .airdrop-section {


    h1 {
      font-size: 1.99rem;
      line-height: 1.9rem;
    }
    p {
      font-weight: 500;
      font-size: 1.25rem;
      line-height: 1.75rem;
      color: #ffffff;
      font-family: "Roboto";
      letter-spacing: 0.5px;
    }
    img{
      width: 50% !important;
      margin: auto !important;
      right: 20% !important;
    }
  }
}
